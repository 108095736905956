<template>
    <div class="dating-reviews" @scroll="onScroll">
        <div class="header-bar">
            <transition name="slide-down">
                <div v-show="showHiddenHeader" class="title" v-html="title"></div>
            </transition>
            <div @click="$stackRouter.pop()" class="left flex-row items-center flex-wrap">
                <i class="material-icons flex-wrap">chevron_left</i>
            </div>
        </div>
        <div class="header" v-html="header"></div>
        <div class="banner-cont">
            <img class="banner" src="@/assets/images/dating-review/header.jpg" @click="bannerClick" />
        </div>
        <div class="info-box" v-if="reviews.length !== 0">
            <div class="info" v-html="'💝 회원님이 직접 작성한 100% 실제 후기에요! 💝'" />
            <span class="down-arrow" />
        </div>
        <DatingReview
            v-for="review in reviews"
            :key="review.id"
            :review="review"
            :divider="reviews.indexOf(review) < reviews.length - 1"
            :agent="agent"
            class="m-t-20"
            @click="goToUserDetail(item)"
        />
        <div class="center m-t-20" v-if="reviews.length === 0">등록된 후기가 없습니다</div>
        <div class="bottom">
            <div class="button" @click="btnClick">{{ cta }}</div>
        </div>
    </div>
</template>

<script>
import DatingReview from './DatingReview'
import datingReviewService from '@/services/dating-review'

export default {
    name: 'DatingReviewsPage',
    components: {
        DatingReview,
    },
    props: ['agent'],
    data: () => ({
        reviews: [],
        showHiddenHeader: false,
    }),
    computed: {
        title() {
            if (this.agent) {
                return `주선자 ${this.agent.name} - 실제 커플 후기`
            } else {
                return '커플 후기'
            }
        },
        header() {
            if (this.agent) {
                return `주선자 ${this.agent.name}님이 이어준<br>실제 커플 후기`
            } else {
                return '커플 후기'
            }
        },
        cta() {
            if (this.agent) return '다른 주선자 후기 보기'
            else return '커플 후기 작성하기'
        },
    },
    methods: {
        async init() {
            if (this.agent) {
                this.reviews = this.agent.dating_reviews
            } else {
                this.reviews = await datingReviewService.all()
            }
        },
        btnClick() {
            if (this.agent) this.$stackRouter.push({ name: 'DatingReviewsPage' })
            else this.$stackRouter.push({ name: 'DatingCandidateSelectPage' })
        },
        bannerClick() {
            this.$stackRouter.push({ name: 'DatingReviewInfoPage' })
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > 120
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-reviews {
    padding-top: 56px;
    padding-bottom: 120px;
    overflow-y: auto;

    .header-bar {
        position: fixed;
        justify-content: space-between;
        left: 0;
        right: 0;
        z-index: 2;
        border-bottom: none;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            height: 52px;
            top: 0;
            left: 0;
            right: 0;
            background: white;
            border-bottom: 1px solid $grey-03;
            z-index: 1;
            color: black;
            @include f-medium;
        }

        .left {
            z-index: 1;
            position: fixed;
            top: 16px;
            left: 16px;

            .material-icons {
                color: $grey-08;
            }
        }
    }

    .header {
        padding: 0 20px;
        font-size: 24px;
        letter-spacing: -0.4px;
        color: black;
        @include f-medium;
    }

    .banner-cont {
        padding: 0 20px;
        margin-top: 40px;

        .banner {
            width: 100%;
            border-radius: 12px;
        }
    }

    .info-box {
        margin: 32px 20px 0 20px;
        position: relative;
        background: $purple-review;
        height: 51px;
        border-radius: 12px;
        padding: 14px 20px;
        font-size: 14px;
        color: black;

        .info {
            text-align: center;
        }

        .down-arrow {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px;
            border-color: $purple-review transparent transparent transparent;
            position: absolute;
            left: calc(50% - 6px);
            bottom: -12px;
        }
    }

    .bottom {
        height: 80px;
        width: 100%;
        border-top: 1px solid $grey-02;
        padding: 16px;
        position: absolute;
        bottom: 0;
        background: white;

        .button {
            height: 48px;
            width: 100%;
            line-height: 48px;
            color: $white;
            @include f-medium;
            font-size: 15px;
            border-radius: 8px;
            background: $purple-primary;
            text-align: center;
        }
    }
}
</style>
